// ProductsList.js
import React, { useEffect, useState } from 'react';
// import './ProductsList.css';
import styled from 'styled-components'
import { PRODUCTS_URL } from '../common/urls';
import { getRequest } from '../util/request';
import AuthService from '../common/AuthService';
import LoadingOverlay from '../common/LoadingOverlay';

const ProductDashboardTile = styled.div`
  .products-list{
    min-height: 300px;
    max-height: 420px;
    overflow: auto;
    max-width: 450px;
    width: 500px;
    border-radius: 20px;
    position: relative;

    .product-row{
      display: flex;
      flex-direction: row;
      padding: 5px 10px;
      /* justify-content: space-between; */
      align-items: center;
      color: gray;
      &:nth-child(even){
        background-color: #fafafa;
      }
      &:nth-child(odd){
        background-color: #f9fff9;
      }

      .product-detail{
        // margin-left: 10px;
        text-align: left;
        /* font-weight: bold; */
        min-height: 60px;

        .product-detail-title{
          font-size: 10px;
          font-weight: normal;
          margin-bottom: 5px;
        }

        &.product-detail-label{
          flex: 2;
          .product-detail-value{
            overflow: hidden;
            // word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 50px;
          }
        }

        &.product-detail-price{
          flex: 1;
        }

        &.product-detail-image{
          flex: 1;

          .product-img{
            width: 70px;
            max-height: 100px;
            min-width: 50px;
            min-height: 50px;
            border: 1px solid #dfdfdf;
            background-color: white;
            padding: 4px;
          }
        }

        &.product-detail-status{
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          flex: 1;
          flex-direction: column;
          align-items: flex-end;

          .product-detail-value{
            text-align: center;
            padding: 5px;
            border-radius: 5px;
            font-size: 14px;
            width: 70px;
            background-color: #efefef;
            color: rgb(95, 94, 89);
            // border: 1px solid  rgb(82, 81, 76);

            &.status-active{
              background-color: #CCD9CE; 
              color: #56805B;
              // border: 1px solid #1eb51e;
              border: 0;
            }

            &.status-sold-out{
              background-color: #FEF3F2;
              color: #F04438;
              border: 0;
              // border: 1px solid #f2d95c;
            }
          }
        }
      }
    }
  }
`

const PaginationBar = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  .page-title{
    font-weight: bold;
  }
  .available-pages{
    .current-page{
      color: #d462ff;
      font-weight: bold;
      margin: 0 8px;
    }
    .page-link{
      margin: 0 5px;
      text-decoration: underline;
      cursor: pointer;

      &.disabled{
        pointer-events: none;
        text-decoration: none;
        color: #a9caad;
        cursor: default;
      }
    }
  }
`

const PAGE_SIZE = 15;

function ProductsList(props) {
  const [page, setPage] = useState(1);
  const [variants, setVariants] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const adminViewAs = props.adminViewAs;
  const isShowAsCustomer = props.showAsCustomer;
  useEffect(() => {
    // console.log()
    if(AuthService.isLoggedIn()){
      setIsLoading(true);
      getRequest(
        `${PRODUCTS_URL}?page=${page}${isShowAsCustomer ? `&customerId=${adminViewAs}` : ""}`, 
        (response)=> {
          // console.log(response.variants);
          setVariants(response.variants);
          setTotalPages(response.total_pages);
          setIsLoading(false);
        },
        () => {setIsLoading(false)}
      )
    }
  }, [page, isShowAsCustomer])
  var products = variants;
  // console.log(products);
  const getStatusObject = (product) => {
    var stock = product.inventory_quantity;
    var statusLabel = product.parent.status; 
    var className = "status-active"
    // if(product.variants){
      // product.variants.forEach((variant) => {stock += variant.inventory_quantity});
    // }
    if (statusLabel.toLowerCase() == 'active') {
      if(!stock){
        statusLabel = "Sold Out"
        className = "status-sold-out";
      } else {
        statusLabel = "Online"
      }
      return {
        label: statusLabel.charAt(0).toUpperCase() + statusLabel.slice(1),
        className: className,
        stock: stock
      };
    } else {
      className = "status-unknown";
    }
    return {
      label: statusLabel.charAt(0).toUpperCase() + statusLabel.slice(1),
      className: className,
      stock: stock
    };
  }
  // var pagesSpan = []; 
  var lastPage = totalPages;
  var pagesSpan = (
    <>
      <span 
        className={`page-link ${totalPages <= 1 ? `disabled` : ``}`}
        onClick={()=>{setPage(1)}}
      >
        {"<<"}
      </span>
      <span 
        className={`page-link ${totalPages <= 1 ? `disabled` : ``}`}
        onClick={()=>{setPage(page - 1)}}
      >
        {"<"}
      </span>
      <span className="current-page">{page} / {totalPages}</span>
      <span 
        className={`page-link ${page == lastPage ? `disabled` : ``}`}
        onClick={()=>{setPage(page + 1)}}
      >
        {">"}
      </span>
      <span 
        className={`page-link ${page == lastPage ? `disabled` : ``}`}
        onClick={()=>{setPage(lastPage)}}
      >
        {">>"}
      </span>
    </>
  );
  return (
    <ProductDashboardTile className="dashboard-tile products-dashboard">
      <h3>Products</h3>
      <div className="products-list">
        {products.map((product, index) => {
          const statusObj = getStatusObject(product);
          return (
            <div className="product-row" key={index}>
              <div className="product-detail product-detail-image">
                <img className="product-img" src={product.featured_image ? product.featured_image : product.parent.featured_image}/>
              </div>
              <div className="product-detail product-detail-label" title={`Name: ${product['display_name']}`}>
                <div className="product-detail-title">Name</div>
                <div className="product-detail-value">{product['display_name']}</div>
              </div>
              <div className="product-detail product-detail-status" title={`Status: ${statusObj.label}`}>
                <div className={`product-detail-value ${statusObj.className}`}>{statusObj.label}</div>
              </div>
            </div>
          )
        })}
        <LoadingOverlay isLoading={isLoading}/>
      </div>
      <PaginationBar><div className="page-title">Page:</div> <div className="available-pages">{pagesSpan}</div></PaginationBar>
    </ProductDashboardTile>
  );
}

export default ProductsList;
