// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
import ProductsListTile from './ProductsListTile';
import './Dashboard.css';
import RequestsListTile from './RequestsListTile';
import styled from 'styled-components';
import ReceiptsTile from './ReceiptsTile';
import OverviewTile from './OverviewTile';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Pie } from 'react-chartjs-2';
// ChartJS.register(ArcElement, Tooltip, Legend);

// import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getRequest } from '../util/request';
import { CONDITION_AGGS_URL, PRODUCTS_URL } from '../common/urls';
import AuthService from '../common/AuthService';
ChartJS.register(ArcElement, Tooltip, Legend);

const conditionColors = {
  'neu': '#A6F4C5',
  'neuwertig': '#56805B',
  'sehr gut': '#91AC94',
  'gut': '#A687B4',
  'second hand': '#FEDF89',
  'repaired': '#FDB022',
  'akzeptabel': '#F97066',
  'ok': '#F97066',
  'none': '#D0D5DD'
}

const Dashboard = (props) => {
  const [conditionAggregation, setConditionAggregation] = useState({});
  const [totalProducts, setTotalProdcts] = useState(0);
  // const products = props.products;
  const adminViewAs = props.adminViewAs;
  const showAsCustomer = props.showAsCustomer;
  useEffect(() => {
    getRequest(`${CONDITION_AGGS_URL}${showAsCustomer ? `?customerId=${adminViewAs}` : ""}`, (response) => {
      setTotalProdcts(response.total);
      response['none'] = response['null']
      delete response['null'];
      delete response['total'];
      setConditionAggregation(response)
    })
  }, [props]);
  var ordersData = {
    labels: Object.keys(conditionAggregation).map((condition) => condition.charAt(0).toUpperCase() + condition.slice(1)),
    datasets:[
      {
        label: 'Condition',
        borderColor: '#405d43',
        borderWidth: 1,
        backgroundColor: Object.keys(conditionAggregation).map((condition) => conditionColors[condition.toLowerCase()]),
        legend: {display:false},
        data: Object.values(conditionAggregation).map((conditionCount)=>{return ((conditionCount/totalProducts)*100).toFixed(1)})
      }
    ]
  }
  var i = 0;
  // const options = {
  //   indexAxis: 'y',
  //   elements: {
  //     bar: {
  //       borderWidth: 2,
  //     },
  //   },
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'chartArea',
  //     }
  //   },
  // };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.formattedValue || '';
            label += '%';
            return label;
          }
        }
      }
    },
  };
  console.log(ordersData)
  return (
    <div>
      <h2>Overview</h2>
      <div className="dashboard-page">
        <OverviewTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer}/>
        <ProductsListTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer}/>
        <div className="dashboard-tile">
          <h3>Products Condition</h3>
          <div style={{ width: '400px', margin: '0 auto' }}>
            <Doughnut options={options} data={ordersData} />
          </div>
        </div>
        <ReceiptsTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer}/>
        <RequestsListTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer}/>
      </div>
    </div>
  );
};

export default Dashboard;
