import { postRequest, request } from "../util/request";
import { BASE_URL, LOGIN_URL } from "./urls";
import jwt from 'jwt-decode';

export const LOGIN_AUTH_TOKEN_KEY = "RAAS_LOGIN_AUTH_TOKEN"
export const LOGIN_REFRESH_TOKEN_KEY = "RAAS_LOGIN_REFRESH_TOKEN"
export const LAST_LOGIN_TIME_KEY = "RAAS_LAST_LOGIN_TIME"
export const USER_DETAIL_KEY = "RAAS_USER_DETAIL"

const _setUserInfo = (userInfo) => {
    window.userInfo = userInfo;
}

var _getUserInfo = (fCallback) => {
    const existingUserInfo = window.userInfo;
    if(!existingUserInfo){
        //     // console.log("IN")
        const loginAuth = localStorage.getItem(LOGIN_AUTH_TOKEN_KEY);
        const decodedAccessToken = jwt(loginAuth);
        const data = { user_id: decodedAccessToken.user_id };
        const endpoint = `auth/get-user/`;
        postRequest(endpoint, data, (res) => {
            _setUserInfo(res);
            fCallback(window.userInfo)
        });
    }  else {
        fCallback(window.userInfo)
    }
    return existingUserInfo;
}

const setToken = (accessToken, refreshToken) => {
    localStorage.setItem(LOGIN_AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(LOGIN_REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(LAST_LOGIN_TIME_KEY, new Date(Date.now()).getTime());
};
  
const getToken = () => {
    const lastLogin = localStorage.getItem(LAST_LOGIN_TIME_KEY);
    const loginAuth = localStorage.getItem(LOGIN_AUTH_TOKEN_KEY);
    if(lastLogin && loginAuth){
        return loginAuth;
    } else {
        deleteToken();
        return undefined;
    }
};

const deleteToken = () => {
    localStorage.removeItem(LAST_LOGIN_TIME_KEY);
    localStorage.removeItem(LOGIN_REFRESH_TOKEN_KEY);
    localStorage.removeItem(LOGIN_AUTH_TOKEN_KEY);
  }

const handleloginSuccess = (callback, user) => {
    // console.log(user);
    if(user.access && user.refresh){
        // console.log(user);
        setToken(user.access, user.refresh);
    }
    callback();
}

const AuthService = {

    invalidateAuth: () => {
        deleteToken();
    },

    setUserInfo: (userInfo) => {
        _setUserInfo(userInfo);
    },
    
    getUserInfo: (fCallback) => {
        return _getUserInfo(fCallback);
    },

    loginUser: (email, password, callbackOnSuccess, callbackOnFailure) => {
        const requestBody = {
            email: email,
            password: password,
        };
        const sURL = `${BASE_URL}${LOGIN_URL}`;
        const options = {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };
        request(sURL, options)
            .then((response) => {
                if(response.access && response.refresh){
                    setToken(response.access, response.refresh);
                }
                callbackOnSuccess(response.access);
            })
            .catch((err) => {
                console.error(err)
                callbackOnFailure(err);
            });
    },

    logoutUser: () => {
        deleteToken();
        window.location.reload();
    },

    getUserDetails: function() {
        // sessionStorage.removeItem(USER_DETAIL_KEY);
        // var userString = sessionStorage.getItem(USER_DETAIL_KEY);
        // var user = undefined;
        // if(!userString){
        //     user = {"firstName": "TEST", "lastName": "LASTTEST"}
        //     sessionStorage.setItem(USER_DETAIL_KEY, JSON.stringify(user));
        // } else {
        //     user = JSON.parse(userString);
        // }
        // return user;
    },

    isLoggedIn: function() {
        // deleteToken();
        if(getToken())
            return true
        else
            return false;
    }
};

export default AuthService;