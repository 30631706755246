import React, { useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import AuthService from "../common/AuthService";
import { userLoggedIn } from "./MainScreen/MainScreenActions";
import tildi_logo from '../../static/tildi_logo.png'

const LoginWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    .logo-wrapper{
        margin: 20px auto;
        width: 7%;
        position: relative;
        .tildi-logo{
            height: 70px;
        }
        .logo-text{
            position: absolute;
        }
    }
    .login-screen-content-wrapper{
        background-color: #ffffffa1;
        margin: 100px 200px;
        padding: 40px 0;
        .login-screen-title{
            // margin-top: 150px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
        form.login-input-wrapper{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin: 50px 0;
            input.login-input{
                padding:10px;
                min-width: 30%;
                margin: 20px 0;
            }
            .login-button{
                font-weight: bold;
                border: none;
                padding: 10px 20px;
                font-size: 15px;
                background-color: #3e5d43;
                color: white;
            }
        }
        .forgot-password-wrapper{
            display: flex;
            justify-content: center;
        }
    }
`

const LOGIN_SCREEN_STORE_KEY = "LoginScreen";

const LoginScreen = (state) => {
    const [loginDetails, setLoginDetails] = useState({
        email: "",
        password: ""
    });
    // store.injectReducer(LOGIN_SCREEN_STORE_KEY, LoginScreenReducer);
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        const redirectOnLoginSuccess = (auth_token) => {
            toast.success("Success", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            dispatch(userLoggedIn(auth_token));
        }

        const raiseErrorOnFailure = (err) => {
            toast.error(err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            // dispatch(loginFailed(err));
        }

        event.preventDefault();
        // loginUser(loginDetails.email, loginDetails.password, redirectOnLoginSuccess, raiseErrorOnFailure);
        AuthService.loginUser(loginDetails.email, loginDetails.password, redirectOnLoginSuccess, raiseErrorOnFailure);
  };


    const handleChange = (event) => {
        // dispatch(loginInputChange(event.target.name, event.target.value));
        var newLoginDetails = {...loginDetails};
        newLoginDetails[event.target.name] = event.target.value;
        setLoginDetails(newLoginDetails);
    };

  return (
    <LoginWrapper>
        <div className="logo-wrapper">
            <img className="tildi-logo" src={tildi_logo}/>
        </div>
        <div className="login-screen-content-wrapper">
            <div className="login-screen-title">Login</div>
            <form onSubmit={handleSubmit} className="login-input-wrapper">
                <input 
                    name="email"
                    type="email" 
                    placeholder="E-Mail Address" 
                    className="login-input login-input-username"
                    value={state.email}
                    onChange={handleChange}
                />
                <input 
                    name="password"
                    type="password" 
                    placeholder="Password" 
                    className="login-input login-input-password"
                    value={state.password}
                    onChange={handleChange}
                />
                <input className="login-button" type="submit" value="Login"/>
            </form>
            <div className="forgot-password-wrapper"><a href="/forgot-password" className="forgot-password">Forgot your Password?</a></div>
        </div>
    </LoginWrapper>
  );
};

export default LoginScreen;
