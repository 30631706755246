import { AUTHENTICATED, CURRENT_USER_FETCHED, DETAILS_FETCHED, NOT_AUTHENTICATED, USER_LOGIN_FAIL, USER_LOGIN_SUCCESS } from "./MainScreenActionTypes";

export const initialState = {
  loginAttempt : {
    loggedIn: false,
    currentToken: undefined,
    lastLoginStatus: undefined,
    lastLoginAttempt: undefined
  },
  currentUser: undefined,
  products: [],
  customers: []
};


const MainScreenReducer = (state = initialState, action) =>{
  // console.log(action);
  switch (action.type) {

    case USER_LOGIN_SUCCESS:
      // console.log(state.currentToken)
      // console.log(action.token)
      var newState = {...state}
      newState.loginAttempt = {
        loggedIn: true,
        currentToken: action.token,
        lastLoginStatus: "SUCCESS",
        lastLoginAttempt: new Date(),
        message: action.message
      }
      // console.log(newState);
      return newState;

    case CURRENT_USER_FETCHED:
      var newState = {...state}
      newState.currentUser = action.currentUser;
      return newState;
      
    case USER_LOGIN_FAIL:
      var newState = {...state}
      newState.loginAttempt = {
        loggedIn: false,
        currentToken: undefined,
        lastLoginStatus: "FAILURE",
        lastLoginAttempt: new Date(),
        message: action.message
      }
      // console.log(newState);
      return newState;

    case DETAILS_FETCHED:
      var newState = {...state};
      newState.customers = action.customers;
      newState.products = action.products;
      newState.currentUser = action.currentUserInfo;
      return newState;

    default:
      return state;
    }
};

export default MainScreenReducer;