import React, { useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import AuthService from "../common/AuthService";
import { userLoggedIn } from "./MainScreen/MainScreenActions";
import tildi_logo from '../../static/tildi_logo.png'
import { useNavigate } from "react-router-dom";
import { postRequest } from "../util/request";
import { RESET_PASSWORD_URL } from "../common/urls";

const LoginWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    .logo-wrapper{
        margin: 20px auto;
        width: 7%;
        position: relative;
        .tildi-logo{
            height: 70px;
        }
        .logo-text{
            position: absolute;
        }
    }
    .login-screen-content-wrapper{
        background-color: #ffffffa1;
        margin: 100px 200px;
        padding: 40px 0;
        .login-screen-title{
            // margin-top: 150px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
        form.login-input-wrapper{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin: 50px 0;
            input.login-input{
                padding:10px;
                min-width: 30%;
                margin: 20px 0;
            }
            .login-button{
                font-weight: bold;
                border: none;
                padding: 10px 20px;
                font-size: 15px;
                background-color: #3e5d43;
                color: white;
            }
        }
        .forgot-password-wrapper{
            display: flex;
            justify-content: center;
        }
    }
`

const ForgotPasswordScreen = (state) => {
    const [forgotPasswordDetails, setForgotPasswordDetails] = useState({
        email: ""
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        const redirectOnLoginSuccess = () => {
            toast.success("A email with your password reset link will be sent to your registered email id", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            navigate("/");
        }

        const raiseErrorOnFailure = (err) => {
            toast.error(err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            // dispatch(loginFailed(err));
        }

        event.preventDefault();
        // loginUser(loginDetails.email, loginDetails.password, redirectOnLoginSuccess, raiseErrorOnFailure);
        // AuthService.loginUser(loginDetails.email, loginDetails.password, redirectOnLoginSuccess, raiseErrorOnFailure);
        postRequest(RESET_PASSWORD_URL, {email: forgotPasswordDetails.email}, redirectOnLoginSuccess, raiseErrorOnFailure)
  };


    const handleChange = (event) => {
        // dispatch(loginInputChange(event.target.name, event.target.value));
        var newLoginDetails = {...forgotPasswordDetails};
        newLoginDetails[event.target.name] = event.target.value;
        setForgotPasswordDetails(newLoginDetails);
    };

  return (
    <LoginWrapper>
        <div className="logo-wrapper">
            <img className="tildi-logo" src={tildi_logo}/>
        </div>
        <div className="login-screen-content-wrapper">
            <div className="login-screen-title">Forgot Password?</div>
            <form onSubmit={handleSubmit} className="login-input-wrapper">
                <input 
                    name="email"
                    type="email" 
                    placeholder="E-Mail Address" 
                    className="login-input login-input-username"
                    value={state.email}
                    onChange={handleChange}
                />
                <input className="login-button" type="submit" value="Reset Password"/>
            </form>
        </div>
    </LoginWrapper>
  );
};

export default ForgotPasswordScreen;
