// src/components/Login.js
import React, { useState } from 'react';
import styled from 'styled-components';
// import logoutIcon from '../../static/logout_icon.png'
import { useNavigate } from 'react-router-dom';
import AuthService from '../common/AuthService';
import {ReactComponent as LogoutIcon} from '../../static/tildi-logout-icon.svg';

const UserCustomerDetailsWrapper = styled.div`
    display: flex;
    height: 80px;
    // background-color: #f1f8f2;
    background-color: #F3F8F7;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    bottom: 0;
    // box-shadow: -1px 2px 11px 0px #b0b0b0;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    justify-content: space-around;
    user-select: none;

    .user-photo{
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        background-color: #3e5d43;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        font-size: 16px;
    }
    .user-name{
        color: #3e5d43;
        font-size: 12px;
        max-width: 110px;
        font-weight: bold;
    }

    .logout-button{
        cursor: pointer;
        .logout-icon{
            height: 20px;
            path{
                fill: #56805B;
            }
        }
    }
`

const logoutUser = () => {
    AuthService.logoutUser();
}

const UserCustomerDetails = (props) => {
    const user = props.user;
    return (
        <UserCustomerDetailsWrapper>
            <div className="user-photo">
                {user.first_name.substring(0,1)}
            </div>
            <div className="user-name">
                {user.first_name} {user.last_name}
            </div>
            <div className="logout-button">
                <LogoutIcon className="logout-icon" onClick={logoutUser} />
            </div>
        </UserCustomerDetailsWrapper>
    );
};

export default UserCustomerDetails;
