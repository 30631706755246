import { useEffect, useState } from "react";
import styled from "styled-components";
import { getRequest } from "../../../util/request";
import { USERS_URL } from "../../../common/urls";

const UserAdminScreenWrapper = styled.div`
    height: 100%;
    width: 100%;
    .create-user-section{
        margin: 10px auto;
        .user-admin-screen-title{
            text-align: center;
        }
        .user-admin-form-wrapper{
            width: 50%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            // margin: 20px auto;
            .user-admin-field-wrapper{
                // width: 50%;
                margin: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .user-field{
                margin: 0 10px;
                padding: 5px 10px;
            }
            .field-input{
                width: 200px;
                max-width: 200px;
            }
            .user-submit-button{
                width: 100px;
                height: 30px;
                margin: 30px auto;
            }
            select.field-input{
                width: 225px;
                max-width: 225px;
            }
        }
    }
    .existing-user-section{
        padding: 20px;
        background-color: #ffffff6e;
        .existing-user-section-header{
            text-align: center;
        }
        .existing-users-list{
            margin: 20px auto;
            padding: 20px;
            width: 90%;
            table{
                text-align: left;
                width: 100%;
                .table-cell{
                    padding: 5px;
                    height: 10px;
                }
                .name-cell{
                    width: 50px;
                }
                .email-cell{
                    width: 80px;
                }
                .last-login-cell{
                    width: 80px;
                }
            }
        }
    }
`

const UserAdminScreen = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [linkedCustomerId, setLinkedCustomerId] = useState(-1);
    const [usersList, setUsersList] = useState([]);

    const raasCustomers = props.raasCustomers;
    var screenToDisplay = (<div></div>);
    const resetForm = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setLinkedCustomerId(-1)
        getRequest(`${USERS_URL}`, (users) => {setUsersList(users)})
    }

    useEffect(()=>{
        getRequest(`${USERS_URL}`, (users) => {setUsersList(users)})
    },[]);
    return (
        <UserAdminScreenWrapper>
            <div className="create-user-section">
                <h2 className="user-admin-screen-title">Create New User</h2>
                <form 
                    className="user-admin-form-wrapper" 
                    onSubmit={(e)=>{
                        var generateUserBody = {
                            firstName: firstName,
                            lastName: lastName,
                            email: email,
                            customerId: linkedCustomerId
                        }
                        // console.log(generateUserBody)
                        e.preventDefault();
                        props.onCreateNew(generateUserBody, ()=>{resetForm()})
                    }}
                >
                    <div className="user-admin-field-wrapper">
                        <label className="user-field field-label" for="user-email">Email</label>
                        <input 
                            className="user-field field-input" 
                            id="user-email" 
                            type="email"
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                        />
                    </div>
                    <div className="user-admin-field-wrapper">
                        <label className="user-field field-label" for="user-first-name">First Name</label>
                        <input 
                            className="user-field field-input" 
                            id="user-first-name" 
                            type="text"
                            value={firstName}
                            onChange={(e)=>{setFirstName(e.target.value)}}
                        />
                    </div>
                    <div className="user-admin-field-wrapper">
                        <label className="user-field field-label" for="user-last-name">Last Name</label>
                        <input 
                            className="user-field field-input" 
                            id="user-last-name" 
                            type="text"
                            value={lastName}
                            onChange={(e)=>{setLastName(e.target.value)}}
                        />
                    </div>
                    <div className="user-admin-field-wrapper" onChange={(e)=>{setLinkedCustomerId(e.target.value)}}>
                        <label className="user-field field-label" for="user-email">RaaS Customer</label>
                        <select value={linkedCustomerId} className="user-field field-input" >
                            <option disabled selected value={-1}>-- Select Customer --</option>
                            {raasCustomers.map((raasCustomers) => {
                                return (<option value={raasCustomers.id}>{raasCustomers.name}</option>);
                            })}
                        </select>
                    </div>
                    {/* <input className="user-field field-input" id="user-email" type="email"/> */}
                    <button className="user-submit-button" type="submit">Send Invite</button>
                </form>
            </div>
            <div className="existing-user-section">
                <h2 className="existing-user-section-header">Users</h2>
                <div className="existing-users-list">
                    <table>
                        <tr className="users-header-row">
                            <th className="table-cell name-cell">First Name</th>
                            <th className="table-cell name-cell">Last Name</th>
                            <th className="table-cell email-cell">Email</th>
                            <th className="table-cell last-login-cell">Last Login</th>
                        </tr>
                        {usersList.map((user)=>{
                            var lastLogin = user.last_login;
                            return (
                                <tr className="users-row">
                                    <td className="table-cell name-cell">{user.first_name}</td>
                                    <td className="table-cell name-cell">{user.last_name}</td>
                                    <td className="table-cell email-cell">{user.email}</td>
                                    <td className="table-cell last-login-cell">{lastLogin ? new Date(lastLogin).toDateString() : "--"}</td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            </div>
        </UserAdminScreenWrapper>
    )
}

export default UserAdminScreen;