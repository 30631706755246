import styled from 'styled-components';
import { connect, useDispatch } from "react-redux";
import tildi_logo from '../../../static/tildi_logo.png'
import Dashboard from '../Dashboard';
import { Navigate, Route, Routes, useLocation } from "react-router";
import Inventory from '../Inventory';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../../common/AuthService';
import LoginScreen from '../LoginScreen';
import { initialState } from "./MainScreenReducer";
import { ToastContainer, toast } from 'react-toastify';
import UserCustomerDetailsWrapper from '../UserCustomerDetails'
import { detailsFetched, getCustomers, getProducts, userFetched } from './MainScreenActions';
import AdminScreen from '../AdminScreen/AdminScreen'
import ActivateAccountScreen from '../ActivateAccountScreen';
import {ReactComponent as HomeIcon} from '../../../static/tildi-home-icon.svg';
import {ReactComponent as InventoryIcon} from '../../../static/tildi-inventory-icon.svg';
import {ReactComponent as ProfileIcon} from '../../../static/tildi-profile-icon.svg';
import Sales from '../Sales';
import ForgotPasswordScreen from '../ForgotPasswordScreen';

const MainScreenWrapper = styled.div`
    // height: 100%;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;
    // overflow: hidden;
    // width: 100%;
    position: relative;
`

const LeftBar = styled.div`
    position: relative;
    float: left;
    display: flex;
    width: 210px;
    background-color: white;
    box-shadow: -1px 0px 9px 1px #797979;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: calc(100vh);
    user-select: none;

    .logo-wrapper{
        margin-top: 50px;
        position: relative;
        .tildi-logo{
            width: 100px;
        }
        .logo-text{
            position: absolute;
            top: 54px;
            font-size: 12px;
            left: -2px;
        }
    }
    .customer-logo-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        position: relative;
        .customer-logo{
            max-height: 50px;
            max-width: 120px;
        }
        .remove-view-as-button{
            position: absolute;
            right: 40px;
            font-weight: bold;
            cursor: pointer;
            color: red;
            text-decoration: underline;
        }
    }
    .view-as-link{
        text-decoration: underline;
    }
    .left-bar-nav{
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-top: 150px;
        width: 100%;
        .left-bar-nav-item{
            display: flex;
            text-decoration: none;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            color: black;
            width: 100%;
            padding: 15px 0;
            text-align: left;
            &.selected{
                color: #56805B;
                background-color: #F3F8F7;
                .nav-button-icon{
                    path{
                        fill: #56805B;
                    }
                }
                .nav-button-icon-stroke{
                    path{
                        stroke: #56805B;
                    }
                }
            }
            .nav-button-text{
                margin-left: 15px;
            }
            .nav-button-icon, .nav-button-icon-stroke{
                margin-left: 30px;
            }
        }
    }
`

const RightWindow = styled.div`
    float: right;
    width: calc(100vw - 30px - 210px);
    max-height: calc(100vh);
    overflow: auto;
`

const ViewAsDialogOverlay = styled.div`
    visibility: ${props => props.show};
    position: absolute;
    height: calc(100vh);
    width: calc(100vw);
    background-color: #00000042;
    .view-as-dialog{
        // position: absolute;
        width: 600px;
        height: 300px;
        background-color: white;
        margin: 100px auto;
        border-radius: 10px;
        padding: 10px 30px;
    }
`

export const MAIN_SCREEN_STORE_KEY = "MainScreen";
const OVERVIEW_SCREEN = "overview";
const INVENTORY_SCREEN = "inventory";
const SALES_SCREEN = "sales";
const ADMIN_SCREEN = "admin";
const FORGOT_PASSWORD_SCREEN = "forgot-password";

const AuthRoute = ({ children }) => {
    const isLoggedIn = AuthService.isLoggedIn();

    return isLoggedIn ? children : <Navigate to="/login" />;
}

const AdminRoute = ({ children, user }) => {
    // const isLoggedIn = AuthService.isLoggedIn();
    const isAdminUser = user && user.is_superuser;
    return isAdminUser ? children : <Navigate to="/" />;
}

const NonAuthRoute  = ({ children }) => {
    const isLoggedIn = AuthService.isLoggedIn();

    return isLoggedIn ? <Navigate to="/" /> : children;
}

const MainScreen = (state) => {
    const dispatch = useDispatch();
    const isLoggedIn = AuthService.isLoggedIn();
    const [adminViewAs, setAdminViewAs] = useState("");
    const [showViewAsDialog, setShowViewAsDialog] = useState(false);
    const [showAsCustomer, setShowAsCustomer] = useState(false);

    const path = useLocation();

    var products = state.products;
    var currentPath = "";
    if(path.pathname.length > 1){
        currentPath = path.pathname.split("/")[1];
    } else {
        currentPath = OVERVIEW_SCREEN;
    }
    useEffect(() => {
        // console.log()
        if(AuthService.isLoggedIn()){
            // console.log("IN")
            AuthService.getUserInfo((oCurrentUserInfo) => {
                // getProducts((productsResponse) => {
                //     // setProducts(productsResponse)
                    
                // })
                if(oCurrentUserInfo['is_superuser']){
                    getCustomers((customersResponse)=>{
                        // setCustomers(response)
                        dispatch(detailsFetched(oCurrentUserInfo,  [], customersResponse))
                    })   
                } 
                else{
                    dispatch(detailsFetched(oCurrentUserInfo, [], state.customers))
                }
            });
        }
    }, [state.loginAttempt.currentToken])

    const removeViewAs = ()=>{
        setAdminViewAs("")
        setShowAsCustomer(false);
    }
    return (
        <MainScreenWrapper>
            {isLoggedIn ? 
                <LeftBar>
                    <div className="logo-wrapper">
                        <img className="tildi-logo" src={tildi_logo}/>
                    </div>
                    <div className="customer-logo-wrapper">
                        {state.currentUser && state.currentUser.customer ? 
                            <img className="customer-logo" src={state.currentUser.customer.icon}/>
                        :""}
                        {
                            state.currentUser && state.currentUser['is_superuser'] ? 
                            showAsCustomer ? 
                                <><img className="customer-logo" src={state.customers.find((customer)=>{return customer.id == adminViewAs}).icon}/> <div onClick={removeViewAs} className="remove-view-as-button">x</div></>
                                : 
                                <div className="view-as-link" onClick={()=>{setShowViewAsDialog(true)}}>View As</div>
                            :
                            ""
                        }
                    </div>
                    <nav className="left-bar-nav">
                        <Link to={`/${OVERVIEW_SCREEN}`} className={`left-bar-nav-item ${currentPath == OVERVIEW_SCREEN ? ' selected' : ''}`}><HomeIcon className="nav-button-icon"/><span className="nav-button-text">Overview</span></Link>
                        <Link to={`/${INVENTORY_SCREEN}`} className={`left-bar-nav-item ${currentPath == INVENTORY_SCREEN ? ' selected' : ''}`}><InventoryIcon className="nav-button-icon"/><span className="nav-button-text">Inventory</span></Link>
                        {/* <Link to={`/${SALES_SCREEN}`} className={`left-bar-nav-item ${currentPath == SALES_SCREEN ? ' selected' : ''}`}><InventoryIcon className="nav-button-icon"/><span className="nav-button-text">Sales</span></Link> */}
                        {
                            state.currentUser && state.currentUser['is_superuser'] ? 
                            <Link to={`/${ADMIN_SCREEN}`} className={`left-bar-nav-item ${currentPath == ADMIN_SCREEN ? ' selected' : ''}`}><ProfileIcon className="nav-button-icon-stroke"/><span className="nav-button-text">Admin</span></Link>
                            :
                            ""
                        }
                    </nav>
                    {state.currentUser ? <UserCustomerDetailsWrapper user={state.currentUser}/> : ""}
                </LeftBar> : ""
            }
            <RightWindow>
                <Routes>
                    <Route path="/login" element={<NonAuthRoute><LoginScreen/></NonAuthRoute>}/>
                    <Route path="/activate-account/:uid64/:token" element={<NonAuthRoute><ActivateAccountScreen/></NonAuthRoute>}/>
                    <Route index element={<AuthRoute>
                            <Dashboard 
                                adminViewAs={adminViewAs} 
                                showAsCustomer={showAsCustomer} 
                                user={state.currentUser}
                            />
                        </AuthRoute>}/>
                    <Route path={`/${OVERVIEW_SCREEN}`} element={
                        <AuthRoute>
                            <Dashboard 
                                adminViewAs={adminViewAs} 
                                showAsCustomer={showAsCustomer} 
                                user={state.currentUser}
                            />
                        </AuthRoute>
                    }/>
                    <Route path={`/${INVENTORY_SCREEN}`} element={
                        <AuthRoute>
                            <Inventory 
                                adminViewAs={adminViewAs} 
                                showAsCustomer={showAsCustomer}
                                user={state.currentUser}
                            />
                        </AuthRoute>
                    }/>
                    <Route path={`/${SALES_SCREEN}`} element={
                        <AuthRoute>
                            <Sales 
                                adminViewAs={adminViewAs} 
                                showAsCustomer={showAsCustomer} 
                                user={state.currentUser} 
                            />
                        </AuthRoute>
                    }/>
                    <Route path={`/${ADMIN_SCREEN}`} element={
                        <AdminRoute showAsCustomer={showAsCustomer} user={state.currentUser}>
                            <AdminScreen/>
                        </AdminRoute>
                    }/>
                    <Route path={`/${FORGOT_PASSWORD_SCREEN}`} element={
                        <NonAuthRoute>
                            <ForgotPasswordScreen/>
                        </NonAuthRoute>
                    }/>
                </Routes>
            </RightWindow>
            <ToastContainer 
                position="bottom-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
            />
            <ViewAsDialogOverlay show={showViewAsDialog ? 'visible' : 'hidden'}>
                <div className="view-as-dialog">
                    <h2>View As</h2>
                    <select value={adminViewAs} className="customer-field field-input"  onChange={(e)=>{setAdminViewAs(e.target.value)}}>
                        <option disabled selected value={-1}>-- Select Customer To View As --</option>
                        {state.customers.map((customer) => {
                            return (<option value={customer.id}>{customer.name}</option>);
                        })}
                    </select>
                    <button onClick={()=>{setShowAsCustomer(true);setShowViewAsDialog(false)}} className="customer-view-as-button">View As Customer</button>
                </div>

            </ViewAsDialogOverlay>
        </MainScreenWrapper>
    )
}

const mapStateToProps = (state) => {
    const componentState = state[MAIN_SCREEN_STORE_KEY];
    // console.log(componentState)
    return componentState ? componentState : initialState;
  }
  
  export default connect(mapStateToProps)(MainScreen);
  
  