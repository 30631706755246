// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import styled from 'styled-components';
import AuthService from '../common/AuthService';
import { PRODUCTS_URL } from '../common/urls';
import { getRequest } from '../util/request';

const PageWrapper = styled.div`
  width:100%;
  .inventory-page{
    max-height: calc(100vh - 100px);
  }
`

const ProductInventoryWrapper = styled.div`
  max-height: calc(100vh - 150px);
  width: 95%;
  box-shadow: 1px 1px 4px 1px #c7c7c7;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  // width: 90%;
  margin-bottom: 20px;
  .table-wrapper{
    max-height: calc(100vh - 150px);
    overflow: auto;
    .products-tabls{
      border-spacing: 0;
      border-collapse: collapse;
      padding: 10px;
      // border: 0.5px solid #49654c;
      .header-row{
        // border-bottom: 2px solid black;
      }
      tr{
        text-align: left;
        // border-bottom: 0.5px solid black;
  
        &.header-row{
          th{
            position: sticky;
            top:0px;
            padding: 10px 25px;
            background-color: #e6e6e6;
          }
        }
  
        &.product-row:nth-child(even){
          background-color: #f0f7f1;
        }
        &.product-row:nth-child(odd){
          background-color: #fafafa;
        }
        &.product-row{
          td.product-detail{
            margin-left: 10px;
            padding: 10px 25px;
            vertical-align: center;
            
            &.product-detail-label{
              min-width: 400px;
            }
            &.product-detail-condition{
              min-width: 100px;
            }
            &.product-detail-image{
              .product-img{
                width: 90px;
                border: 0.5px solid #b2dcb9;
                padding: 5px;
                background-color: white;
              }
            } 
    
            &.product-detail-status{
              min-width: 100px;
              .product-detail-value{
                text-align: center;
                padding: 5px;
                border-radius: 5px;
                font-size: 14px;
                width: 70px;
                background-color: #c7c7c7;
                color: rgb(95, 94, 89);
                border: 1px solid  rgb(82, 81, 76);
    
                &.status-active{
                  background-color: #b7e3b7; 
                  color: darkgreen;
                  border: 1px solid #1eb51e;
                }
    
                &.status-sold-out{
                  background-color: #fff79b;
                  color: #978944;
                  border: 1px solid #f2d95c;
                }
              }
            }
  
            &.product-detail-condition{
              .condition-quality{
                width: 50px;
                height: 10px;
                border: 1px solid black;
                border-radius: 5px;
                background-color: #d1d1d1;
  
                &.sehr-gut{
                  background-color: #00e200;
                }
  
                &.refurbished{
                  background-color: orange;
                }
              }
            }
          }
        }
      }
    }
  }
`

const PaginationBar = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .page-title{
    font-weight: bold;
  }
  .available-pages{
    .page-link{
      margin: 0 5px;
      text-decoration: underline;
      cursor: pointer;

      &.selected{
        font-weight: bold;
        color: #d462ff;
        text-decoration: none;
      }
    }
  }
`

const getStatusObject = (product) => {
  var stock = product.inventory_quantity;
  var statusLabel = product.parent.status; 
  var className = "status-active"
  // if(product.variants){
  //   product.variants.forEach((variant) => {stock += variant.inventory_quantity});
  // }
  var productStatus = product.parent.status;
  if (productStatus == 'ACTIVE') {
    if(stock == 0){
      statusLabel = "Sold Out"
      className = "status-sold-out";
    }
    return {
      label: statusLabel.charAt(0).toUpperCase() + statusLabel.slice(1),
      className: className,
      stock: stock
    };
  } else {
    className = "status-unknown";
  }
  return {
    label: statusLabel.charAt(0).toUpperCase() + statusLabel.slice(1),
    className: className,
    stock: stock
  };
}

const getStock = (product) => {
  var stock = 0;
  if (product.status == 'active' && (product.variants)) {
    product.variants.forEach((variant) => {stock += variant.inventory_quantity});
  }
  return stock;
}

const getConditionHTML = (zustandText) => {
  if(zustandText == 'sehr gut'){

    return (<div className="condition-quality sehr-gut"></div>)
  } else if(zustandText.includes("refurbished")){

    return (<div className="condition-quality refurbished"></div>)
  } else {

    return (<div className="condition-quality"></div>)
  }
}

const PAGE_SIZE = 25;

const Sales = (props) => {
  const [user, setUser] = useState({});
  const [page, setPage] = useState(1);
  const [variants, setVariants] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const products = variants;
  const showAsCustomer = props.showAsCustomer;
  const shouldShowAdminColumns = user['is_superuser'] && !showAsCustomer;
  useEffect(()=>{
    AuthService.getUserInfo((user) => {setUser(user)});
  },[])
  useEffect(() => {
    // console.log()
    if(AuthService.isLoggedIn()){
      // getRequest(`${PRODUCTS_URL}?page=${page}&size=${PAGE_SIZE}`, (response)=> {
      //   // console.log(response.variants);
      //   setVariants(response.variants);
      //   setTotalPages(response.total_pages);
      // })
    }
  }, [page])
  var pagesSpan = []; 
  for(var pageIndex = 1 ; pageIndex <= totalPages ; pageIndex++){
    pagesSpan.push(
      <span 
        className={`page-link ${pageIndex == page ? "selected" : ""}`}
        onClick={(e) => {setPage(e.target.innerText)}}
      >
          {pageIndex}
      </span>
    );
  }
  return (
    <PageWrapper>
      <h2>Sales</h2>
      <div className="inventory-page">
        <ProductInventoryWrapper>
          <div className="table-wrapper">
            <table className="products-tabls">
              <tr className="header-row">
                <th></th>
                {/* <th>Product Id</th> */}
                <th>Product Name</th>
                <th>Resale Price</th>
                <th>RRP</th>
                <th>Condition</th>
                <th>Brand</th>
                <th>Sale Status</th>
                <th>Stock</th>
                <th>Tildi SKU</th>
                {
                  shouldShowAdminColumns ? 
                  (
                    <>
                      <th>Vendor</th>
                      <th>Fulfillment Center</th>
                    </>
                  ) 
                  : ""
                }
              </tr>
              {products.map((product, index) => {
                var productOptions = product.options;
                // const zustandObject = productOptions.find((option) => {return option.name == "Zustand"})
                const conditionText = product.condition;
                // var variant = product.variants[0];
                // const stock = getStock(product);
                const statusObj = getStatusObject(product);
                // console.log(product)
                return (
                  <tr className="product-row" key={index}>
                    <td className="product-detail product-detail-image">
                      <img className="product-img" src={product.featured_image ? product.featured_image : product.parent.featured_image}/>
                    </td>
                    {/* <td className="product-detail product-detail-product-id" title={`Product Id: ${variant['product_id']}`}>
                      {variant['product_id']}
                    </td> */}
                    <td className="product-detail product-detail-label" title={`Name: ${product['display_name']}`}>
                      {product['display_name']}
                    </td>
                    <td className="product-detail product-detail-price" title={`Price: ${product['price']}`}>
                      {product['price'] ? Number(product['price']).toLocaleString('de-DE') : ""}€
                    </td>
                    <td className="product-detail product-detail-price" title={`RRP: ${product['compare_at_price']}`}>
                      {product['compare_at_price'] ? Number(product['compare_at_price']).toLocaleString('de-DE') : 0}€
                    </td>
                    <td className="product-detail product-detail-condition">
                      {conditionText}
                    </td>
                    <td className="product-detail product-detail-sku" title={`Brand: ${product.parent.brand}`}>
                      {product.parent.brand}
                    </td>
                    <td className="product-detail product-detail-status" title={`Status: ${statusObj.label}`}>
                      <div className={`product-detail-value ${statusObj.className}`}>{statusObj.label}</div>
                    </td>
                    <td className="product-detail product-detail-stock" title={`Stock: ${product.inventory_quantity}`}>
                      {product.inventory_quantity}
                    </td>
                    <td className="product-detail product-detail-sku" title={`Tildi SKU: ${product['sku']}`}>
                      {product['sku']}
                    </td>
                    {
                      shouldShowAdminColumns ? 
                      (
                        <>
                          <td className="product-detail product-detail-sku">
                            {product.parent.seller_name}
                          </td>
                          <td className="product-detail product-detail-sku">
                            {product.warehouse}
                          </td>
                        </>
                      ) 
                      : ""
                    }
                  </tr>
                )
            })}
            </table>
          </div>
        </ProductInventoryWrapper>
      </div>
      <PaginationBar><div className="page-title">Page:</div> <div className="available-pages">{pagesSpan}</div></PaginationBar>
    </PageWrapper>
  );
};

export default Sales;
