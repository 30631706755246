import { CUSTOMERSS_URL, FULFILLMENT_CENTERS_URL, INVOICE_URL } from "../../common/urls";
import { deleteRequest, getRequest, postRequest } from "../../util/request";

export const getCustomers = (onSuccess, onFailure) => {
    getRequest(`${CUSTOMERSS_URL}?additionalAttributes=true`, onSuccess, onFailure);
}

export const getFulfillmentCenters = (onSuccess, onFailure) => {
    getRequest(FULFILLMENT_CENTERS_URL, onSuccess, onFailure);
}

export const uploadInvoice = (invoiceDetails, onSuccess, onFailure) => {
    postRequest(INVOICE_URL, invoiceDetails, onSuccess, onFailure);
}

export const deleteInvoice = (id, invoiceDetails, onSuccess, onFailure) => {
    deleteRequest(`${INVOICE_URL}${id}/`, invoiceDetails, onSuccess, onFailure);
}