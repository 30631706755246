import React, { useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import AuthService from "../common/AuthService";
import { userLoggedIn } from "./MainScreen/MainScreenActions";
import tildi_logo from '../../static/tildi_logo.png'
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../util/request";
import { SET_PASSWORD_URL } from "../common/urls";

const ActivateAccountScreenWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    .logo-wrapper{
        margin: 20px auto;
        width: 7%;
        position: relative;
        .tildi-logo{
            height: 70px;
        }
        .logo-text{
            position: absolute;
        }
    }
    .activate-screen-content-wrapper{
        background-color: #ffffffa1;
        margin: 100px 200px;
        padding: 40px 0;
        .activate-screen-title{
            // margin-top: 150px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
        .activate-screen-description{
            text-align: center;
            margin-top: 20px;
        }
        form.activate-input-wrapper{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin: 20px 0;
            input.activate-input{
                padding:10px;
                min-width: 30%;
                margin: 20px 0;
            }
            .activate-button{
                font-weight: bold;
                border: none;
                padding: 10px 20px;
                font-size: 15px;
                background-color: #3e5d43;
                color: white;
            }
        }
    }
`

const LOGIN_SCREEN_STORE_KEY = "LoginScreen";

const ActivateAccountScreen = (state) => {
    const [loginDetails, setLoginDetails] = useState({
        password1: "",
        password2: ""
    });
    // store.injectReducer(LOGIN_SCREEN_STORE_KEY, LoginScreenReducer);
    // const dispatch = useDispatch();
    const params = useParams();
    const uid64 = params.uid64;
    const token = params.token;
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        const redirectOnLoginSuccess = (auth_token) => {
            toast.success("Success", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            // dispatch(userLoggedIn(auth_token));
            navigate("/login/")
        }
        
        const raiseErrorOnFailure = (err) => {
            console.log(err);
            toast.error(err.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
        }
        var body = {
            token: token,
            password: loginDetails.password1,
            password2: loginDetails.password2,
            uid64: uid64
        }
        postRequest(SET_PASSWORD_URL, body, redirectOnLoginSuccess);
        event.preventDefault();
  };


    const handleChange = (event) => {
        // dispatch(loginInputChange(event.target.name, event.target.value));
        var newLoginDetails = {...loginDetails};
        newLoginDetails[event.target.name] = event.target.value;
        setLoginDetails(newLoginDetails);
    };

  return (
    <ActivateAccountScreenWrapper>
        <div className="logo-wrapper">
            <img className="tildi-logo" src={tildi_logo}/>
        </div>
        <div className="activate-screen-content-wrapper">
            <div className="activate-screen-title">Welcome to Tildi RaaS Monitor. You can soon monitor all your resale activity.</div>
            <div className="activate-screen-description">Please set a password to activate your account</div>
            <form onSubmit={handleSubmit} className="activate-input-wrapper">
                <input 
                    name="password1"
                    type="password" 
                    placeholder="Set Password" 
                    className="activate-input activate-input-username"
                    value={state.email}
                    onChange={handleChange}
                />
                <input 
                    name="password2"
                    type="password" 
                    placeholder="Re-enter Password" 
                    className="activate-input activate-input-password"
                    value={state.password}
                    onChange={handleChange}
                />
                <input className="activate-button" type="submit" value="Activate Account"/>
            </form>
        </div>
    </ActivateAccountScreenWrapper>
  );
};


export default ActivateAccountScreen;
