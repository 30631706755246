import { LOGIN_AUTH_TOKEN_KEY } from "../common/AuthService"
import { API_URL, BASE_URL } from "../common/urls";
import { toast } from "react-toastify";
import AuthService from "../common/AuthService";

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  // console.log(response)
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if(response.status == 403){
    AuthService.invalidateAuth();
    window.location.reload();
  }

  return response.text().then((errorText) => {
    const msg = `${response.statusText}:${errorText}`;
    const error = new Error(msg);
    error.response = response;
    throw error;
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function request(url, options) {
  return fetch(url, options).then(checkStatus).then(parseJSON);
}

function requestWithBody(sRequestMethod, sURLPath, oBody, fSuccessCallback, fFaileruCallback){
  const sURL = `${API_URL}${sURLPath}`;
  const options = {
      method: sRequestMethod,
      body: JSON.stringify(oBody),
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      }
  };
  const authKey = localStorage.getItem(LOGIN_AUTH_TOKEN_KEY);
  if(authKey){
    options.headers['Authorization'] = `Bearer ${authKey}`
  }
  return request(sURL, options)
    .then(
      (response)=>{
        fSuccessCallback(response);
        // toast.success("Request Success", {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // })
      }
    ).catch(
      (err) => {
        console.error(err);
        toast.error(err.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        if(fFaileruCallback){
          fFaileruCallback(err);
        }
      }
    );
}

export function postRequest (sURLPath, oBody, fSuccessCallback, fFaileruCallback) {
  requestWithBody('POST', sURLPath, oBody, fSuccessCallback, fFaileruCallback)
}

export function putRequest (sURLPath, oBody, fSuccessCallback, fFaileruCallback) {
  requestWithBody('PUT', sURLPath, oBody, fSuccessCallback, fFaileruCallback)
}

export function patchRequest (sURLPath, oBody, fSuccessCallback, fFaileruCallback) {
  requestWithBody('PATCH', sURLPath, oBody, fSuccessCallback, fFaileruCallback)
}

export function deleteRequest (sURLPath, oBody, fSuccessCallback, fFaileruCallback) {
  requestWithBody('DELETE', sURLPath, oBody, fSuccessCallback, fFaileruCallback)
}

export async function getRequest (sURLPath, fSuccessCallback, fFaileruCallback) {
  const sURL = `${API_URL}${sURLPath}`;
  const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
  }
  const authKey = localStorage.getItem(LOGIN_AUTH_TOKEN_KEY);
  if(authKey){
    options.headers['Authorization'] = `Bearer ${authKey}`
  }
  request(sURL, options).then(fSuccessCallback).catch((err) => {
    console.error(err);
    toast.error(err.message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    })
    if(fFaileruCallback){
      fFaileruCallback();
    }
  });
}
