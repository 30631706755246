import { useEffect, useState } from "react";
import styled from "styled-components";
import UserAdminScreen from "./components/UserAdminScreen";
import { getRequest, postRequest } from "../../util/request";
import { CUSTOMERSS_URL, FULFILLMENT_CENTERS_URL, GENERATE_NEW_USER } from "../../common/urls";
import CustomerAdminScreen from "./components/CustomerAdminScreen";
import { deleteInvoice, getCustomers, getFulfillmentCenters, uploadInvoice } from "./AdminScreenActions";

const AdminScreenWrapper = styled.div`
    height: 100%;
    .admin-screen-body{
        width: 70%;
        height: 100%;
        margin: 20px auto;
    }
`

const AdminNavBar = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    height: 40px;
    background-color: white;
    font-weight: bold;

    .admin-nav-item{
        padding: 10px;
        font-size: 18px;

        &.selected{
            // text-decoration: underline;
            border-bottom: 3px solid green;
        }
    }
`
const USER_ADMIN_SCREEN_KEY = "USER_SCREEN";
const CUSTOMER_ADMIN_SCREEN_KEY = "CUSTOMER_SCREEN";
const FULFILLMENT_CENTER_ADMIN_SCREEN_KEY = "FULFILLMENT_CENTER_SCREEN";
const AdminScreen = (props) => {
    const [adminScreenSelection, setAdminScreenSelection] = useState(USER_ADMIN_SCREEN_KEY);
    const [raasCustomers, setRaasCustomers] = useState([]);
    const [fulfillmentCenters, setFulfillmentCenters] = useState([]);
    useEffect(() =>{
        refreshData();
    },[]);
    const refreshData = () => {
        console.log("IN HERE");
        getCustomers((response)=>{setRaasCustomers(response)});
        getFulfillmentCenters((response)=>{setFulfillmentCenters(response)});
    }

    const handleNavigation = (e) => {
        setAdminScreenSelection(e.target.dataset.navItem);
    }
    var screenToDisplay = (<div></div>);
    switch(adminScreenSelection){
        case USER_ADMIN_SCREEN_KEY:
            screenToDisplay = (
                <UserAdminScreen 
                    raasCustomers={raasCustomers}
                    onCreateNew={(newUserDetails, fOnSuccess) => {
                        postRequest(GENERATE_NEW_USER, newUserDetails, () => {fOnSuccess(); refreshData();});
                    }} 
                />
            );
            break;
        case CUSTOMER_ADMIN_SCREEN_KEY:
            screenToDisplay = (
                <CustomerAdminScreen 
                    onCreateNew={(newCustomerDetails, fOnSuccess) => {
                        postRequest(CUSTOMERSS_URL, newCustomerDetails, ()=> {fOnSuccess(); refreshData()});
                    }} 
                    onUploadInvoice={(invoiceDetails, onSuccess, onFailure) => {
                        uploadInvoice(invoiceDetails, () => {onSuccess(); refreshData()}, onFailure)
                    }}
                    onDeleteInvoice={(id, invoiceDetails, onSuccess, onFailure) => {
                        deleteInvoice(id, invoiceDetails, () => {onSuccess(); refreshData()}, onFailure)
                    }}
                    fulfillmentCenters={fulfillmentCenters}
                    customers={raasCustomers}
                />);
            break;
    }
    return (
        <AdminScreenWrapper>
            <h2 className="admin-screen-title">RaaS Administration</h2>
            <AdminNavBar>
                <div
                    data-nav-item={USER_ADMIN_SCREEN_KEY} 
                    className={`admin-nav-item ${adminScreenSelection == USER_ADMIN_SCREEN_KEY ? "selected" : ""}`}
                    onClick={handleNavigation}
                >
                    User
                </div>
                <div 
                    data-nav-item={CUSTOMER_ADMIN_SCREEN_KEY} 
                    className={`admin-nav-item ${adminScreenSelection == CUSTOMER_ADMIN_SCREEN_KEY ? "selected" : ""}`}
                    onClick={handleNavigation}
                >
                    Customer
                </div>
                {/* <div 
                    data-nav-item={FULFILLMENT_CENTER_ADMIN_SCREEN_KEY} 
                    className={`admin-nav-item ${adminScreenSelection == FULFILLMENT_CENTER_ADMIN_SCREEN_KEY ? "selected" : ""}`}
                    onClick={handleNavigation}
                >
                    Fulfillment Center
                </div> */}
            </AdminNavBar>
            <div className="admin-screen-body">
                {screenToDisplay}
            </div>
        </AdminScreenWrapper>
    )
}

export default AdminScreen;