// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import MainScreen from './components/screens/MainScreen/MainScreen';
import AuthService from './components/common/AuthService';
import LoginScreen from './components/screens/LoginScreen';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // const isLoggedIn = AuthService.isLoggedIn();
  // const screenToDisplay = isLoggedIn ? (<MainScreen/>) : (<LoginScreen />);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <MainScreen />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
