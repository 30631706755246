// ProductsList.js
import React, { useEffect, useState } from 'react';
// import './ProductsList.css';
import styled from 'styled-components'
import AuthService from '../common/AuthService';
import {ReactComponent as DownloadIcon} from '../../static/tildi-upload-icon.svg';
import { getRequest } from '../util/request';
import { DOWNLOAD_INVOICE_URL, INVOICE_URL } from '../common/urls';
import LoadingOverlay from '../common/LoadingOverlay';

const ReceiptsTileWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .invoices-list{
    max-height: 450px;
    overflow: auto;
    max-width: 450px;
    width: 500px;
    height: 400px;
    
    .invoice-row{
      display: flex;
      flex-direction: row;
      padding: 5px 25px;
      align-items: center;
      color: gray;
      min-height: 50px;
      &:nth-child(even){
        background-color: #fafafa;
      }
      &:nth-child(odd){
        background-color: #effaf1;
      }
      
      .invoice-detail{
        text-align: left;
        // min-height: 40px;

        .invoice-detail-title{
          font-size: 10px;
          font-weight: normal;
          margin-bottom: 5px;
        }

        &.invoice-detail-date{
          flex: 4;
        }
        &.invoice-detail-file-name{
          flex: 7;
        }
        &.invoice-download{
          flex: 1;
          .download-icon-wrapper{
            cursor: pointer;
            /* background-color: #f3f8f7; */
            /* border: 1px solid #405d43; */
            /* border-radius: 20px; */
            padding: 7px 5px;
            height: 18px;
            .download-icon{
                height: 15px;
                transform: rotate(180deg);
            }
          }
        }
      }
    }
    .no-invoices-message{
        display: flex;
        height: 100%;
        /* width: 100%; */
        align-items: center;
        align-content: center;
        justify-content: center;
    }
  }
`

const PAGE_SIZE = 15;

function ReceiptsTile(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const isShowAsCustomer = props.showAsCustomer;
  const adminViewAs = props.adminViewAs;
  const downloadFile = (base64, name) => {
    const a = document.createElement('a');
    a.href = `data:application/octet-stream;base64,${base64}`;
    a.download = name;
    a.click();
  };
  useEffect(() => {
    if(AuthService.isLoggedIn()){
      setIsLoading(true);
      getRequest(
        `${INVOICE_URL}?${isShowAsCustomer ? `&customerId=${adminViewAs}` : ""}`, 
        (response)=> {
          // console.log(response.variants);
          // setVariants(response.variants);
          // setTotalPages(response.total_pages);
          setInvoices(response);
          setIsLoading(false);
        },
        () => {setIsLoading(false)}
      )
    }
  }, [props]);
  // var invoices = [];
  // if(currentUser && currentUser.customer.id){
    // invoices = currentUser.customer.invoices;
  // }
  return (
    <ReceiptsTileWrapper className="dashboard-tile products-dashboard">
      <h3>Receipts / Credit Notes</h3>
      <div className="invoices-list">
        {
            invoices.length > 0 ? 
            invoices.map((invoice, index) => {
                return (
                    <div className="invoice-row" key={index}>
                    <div className="invoice-detail invoice-detail-date">
                        <div className="invoice-detail-title">Date</div>
                        <div className="invoice-detail-value">{new Date(invoice['created']).toDateString()}</div>
                    </div>
                    <div className="invoice-detail invoice-detail-file-name">
                        <div className="invoice-detail-title">File Name</div>
                        <div className="invoice-detail-value">{invoice['filename']}</div>
                    </div>
                    <div className="invoice-detail invoice-download">
                        <div className="invoice-detail-value">
                            <div className="download-icon-wrapper" onClick={()=>{
                                getRequest(`${DOWNLOAD_INVOICE_URL}?invoiceId=${invoice.id}`, (response)=>{downloadFile(response.file, response.fileName);})
                            }}>
                                <DownloadIcon className="download-icon"/>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            })
            :
            <div className="no-invoices-message">No receipts added yet.</div>
        }
        <LoadingOverlay isLoading={isLoading}/>
      </div>
    </ReceiptsTileWrapper>
  );
}

export default ReceiptsTile;
