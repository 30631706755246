import { API_URL, BASE_URL, CUSTOMERSS_URL, PRODUCTS_URL } from "../../common/urls";
import { getRequest } from "../../util/request";
import { CURRENT_USER_FETCHED, USER_LOGIN_SUCCESS, DETAILS_FETCHED } from "./MainScreenActionTypes";


export const userLoggedIn = (authToken) => {
  // console.log("userLoggedIn")
  return {
    type: USER_LOGIN_SUCCESS,
    token: authToken
  };
};

export const userFetched = (oCurrentUserInfo) => {
  return {
    type: CURRENT_USER_FETCHED,
    currentUser: oCurrentUserInfo
  };
};

export const getProducts = (fSuccessCallback, sAsCustomerId) => {
  getRequest(`${PRODUCTS_URL}${sAsCustomerId ? '?customerId=' + sAsCustomerId : ''}`,fSuccessCallback)
};

export const getCustomers = (fSuccessCallback) => {
  getRequest(`${CUSTOMERSS_URL}`,fSuccessCallback)
};

export const detailsFetched = (oCurrentUserInfo, aProducts, aCustomers) => {
  return {
    type: DETAILS_FETCHED,
    customers: aCustomers,
    products: aProducts,
    currentUserInfo: oCurrentUserInfo
  };
};